<template>
  <main
    class="
      flex
      bg-transparent
      xl:px-6
      lg:px-6
      md:px-8
      px-3
      pb-32
      min-h-screen
      text-legend-gray
      dark:text-border-color
    "
  >
    <NotificationToast
      v-if="notification"
      :notification="notification"
      @resetNotification="notification = null"
    />
    <section class="w-full">
      <main>
        <h1
          class="
            text-xl
            font-bold
            capitalize
            text-legend-black
            dark:text-border-color
          "
        >
          Purchase goods
        </h1>
        <section class="w-full flex items-center">
          <div class="w-full flex xl:flex-row lg:flex-row md:flex-col flex-col">
            <dashboard-card
              class="bg-primary-color mr-6"
              @toggleBalance="toggleWalletBalance"
              :show-balance="showWalletBalance"
              :data-set="'balance'"
            >
              <img
                src="@/assets/images/subscription-icon-light.svg"
                alt="go to subscriptions"
                slot="icon"
                class="w-8"
              />
              <span slot="title">Wallet Balance</span>
              <span slot="action" class="text-primary-color">Top up</span>
              <span slot="amount" class="inline-block w-14">
                <span v-if="showWalletBalance" class="w-full"
                  >₦{{ walletBalance }}</span
                >
                <img
                  v-else
                  src="@/assets/images/encrypt.svg"
                  alt="wallet balance"
                  class="w-full"
                />
              </span>
            </dashboard-card>
            <dashboard-card
              class="bg-primary-green dark:bg-dark-mode-green"
              @toggleBalance="toggleRewardPoints"
              :show-balance="showRewardPoints"
              :data-set="'rewards'"
            >
              <img
                src="@/assets/images/reward-pack.svg"
                alt="go to subscriptions"
                slot="icon"
              />
              <span slot="title">Total Reward points</span>
              <span
                slot="action"
                class="text-primary-green dark:text-dark-mode-green"
                >Redeem</span
              >
              <span slot="amount" class="inline-block w-14">
                <span v-if="showRewardPoints" class="full">{{
                  rewardPoints
                }}</span>
                <img
                  v-if="!showRewardPoints"
                  src="@/assets/images/encrypt.svg"
                  alt="reward points"
                  class="w-full"
                />
              </span>
            </dashboard-card>
          </div>
        </section>
        <h2
          class="
            font-bold
            text-lg text-legend-black
            dark:text-border-color
            mt-12
            mb-3
          "
        >
          Purchase categories
        </h2>
        <p>Select from the categories of purchases to shop from</p>
        <LoadingSpinner v-if="!merchantCategories" />
        <ul
          v-else
          class="
            grid
            xl:grid-cols-3
            lg:grid-cols-2
            md:grid-cols-2
            grid-cols-1
            gap-6
            mt-10
            font-semibold
          "
        >
          <li
            class="
              h-full
              p-2
              bg-white
              dark:bg-dark-mode-primary
              rounded-md
              text-center
              flex
              items-center
              justify-center
              cursor-pointer
            "
            @click="merchantsCategory(null)"
          >
            All Stores
          </li>
          <li
            v-for="category in merchantCategories"
            :key="category.id"
            class="
              flex
              items-center
              bg-white
              dark:bg-dark-mode-primary
              rounded-md
              p-2
              cursor-pointer
            "
            @click="merchantsCategory(category)"
          >
            <div class="w-20 h-20 bg-gray-100 rounded-md mr-3">
              <img
                v-if="category.image"
                :src="`${url}/${category.image}`"
                :alt="category.name"
                class="w-full h-full object-cover rounded-md"
              />
            </div>
            <div>{{ category.name }}</div>
          </li>
        </ul>
      </main>
    </section>
  </main>
</template>

<script>
import DashboardCard from '@/components/DashboardCard.vue'
import errorModule from '@/util/error.handle'
import { mapGetters } from 'vuex'

export default {
  name: 'Purchases',
  components: {
    DashboardCard,
    // PaginationComponent,
  },
  mounted() {
    this.fetchMerchantCategories()
  },
  data() {
    return {
      merchantCategories: null,
      notification: null,
      showWalletBalance: false,
      showRewardPoints: false,
      url: process.env.VUE_APP_API_URL_HOST,
    }
  },
  computed: {
    ...mapGetters(['walletBalance', 'rewardPoints']),
  },
  methods: {
    toggleWalletBalance(value) {
      value ? (this.showWalletBalance = true) : (this.showWalletBalance = false)
    },
    toggleRewardPoints(value) {
      value ? (this.showRewardPoints = true) : (this.showRewardPoints = false)
    },
    merchantsCategory(category) {
      this.$store.commit('RESET_CATEGORY_ID')

      if (category) {
        this.$store.commit('SET_CATEGORY_ID', category.id)
      }

      this.$nextTick(() => {
        this.$router.push({
          name: 'PurchaseCategory',
          params: { purchaseCategory: category ? category.name : 'all-stores' },
        })
      })
    },
    async fetchMerchantCategories() {
      try {
        const { status, data } = await this.axios.get(
          '/account/products/branches/categories'
        )
        if (status === 200) {
          this.merchantCategories = data.data
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
  },
}
</script>
